$rhap_time-color: #fff !default;

#rhap_current-time {
  color: $rhap_time-color;
}

#rhap_current-left-time {
  color: $rhap_time-color;
}

